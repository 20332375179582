import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import ContainerWithPadding from "../components/ContainerWithPadding"
import AtsGrid from "../components/AtsGrid"
import AtsCard from "../components/AtsCard"
import { Modal } from "antd"
import PageRoot from "../components/PageRoot"
import { useGlobalValue } from "../hooks/useGlobalValue"
import { isSubscriptionValid, isUndefined } from "../utils"
import LoadingScreen from "../components/LoadingScreen"
import ErrorScreen from "../components/ErrorScreen"
import { NO_PLAN_ACTIVE } from "../../functions/lib/error-codes"
import { LAWFUL_HUMAN_3D_ROLES } from "../enums/UserRoles"

const freeCollections = ["BP"]

const HumanCollectionDetailPage = ({ pageContext }) => {
  const { collection, contentList } = pageContext
  const [viewWidth, setViewWidth] = useState(800)
  const [viewHeight, setViewHeight] = useState(600)
  const [checkingPermission, setCheckingPermission] = useState(true)
  const [subscriptionValid, setSubscriptionValid] = useState(false)

  const { collection_name, collection_id, collection_description } = collection
  const [currentHuman, setCurrentHuman] = useState(null)
  const pageTitle = collection_name

  const [{ userSubscription, user, isLoadingUser }] = useGlobalValue()

  const iframeRef = useRef()

  useEffect(() => {
    if (isLoadingUser) {
      return
    }

    if (!isUndefined(userSubscription)) {
      setSubscriptionValid(isSubscriptionValid(userSubscription))
    }

    setCheckingPermission(false)
  }, [userSubscription])

  const humanIframe = useMemo(() => {
    if (checkingPermission || isLoadingUser) {
      return <LoadingScreen />
    }

    if (!freeCollections.includes(collection_id)) {
      if (!LAWFUL_HUMAN_3D_ROLES.includes(user?.role)) {
        if (!subscriptionValid) {
          return <ErrorScreen error={new Error(NO_PLAN_ACTIVE)} />
        }
      }
    }

    if (currentHuman) {
      return (
        <iframe
          id="embedded-human"
          frameBorder="0"
          width={viewWidth}
          height={viewHeight}
          allowFullScreen={true}
          loading="lazy"
          src={currentHuman?.content_url}
        />
      )
    } else {
      return null
    }
  }, [currentHuman, checkingPermission, subscriptionValid, user, isLoadingUser])

  useLayoutEffect(() => {
    const width = window.innerWidth
    const height = window.innerHeight
    setViewWidth(width)
    setViewHeight(height - 60)
  }, [])

  return (
    <PageRoot title={pageTitle} description={collection_description} showTitle>
      <ContainerWithPadding>
        <AtsGrid
          colSettings={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 4, xxl: 3 }}
          dataSource={contentList}
          renderItem={(item, index) => {
            const { content_thumbnail_url, content_title } = item
            return (
              <AtsCard
                cover={content_thumbnail_url}
                title={content_title}
                type="full"
                onClick={() => {
                  setCurrentHuman(item)
                }}
              />
            )
          }}
        />
        <Modal
          className="fullscreen-modal"
          width={"100%"}
          visible={currentHuman}
          footer={null}
          title={currentHuman?.content_title}
          onCancel={() => {
            setCurrentHuman(null)
          }}
        >
          {humanIframe}
        </Modal>
      </ContainerWithPadding>
    </PageRoot>
  )
}

export default HumanCollectionDetailPage
